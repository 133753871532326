/*
 * @Author: lbw
 * @Date: 2022-09-17 15:14:38
 * @LastEditors: lbw
 * @LastEditTime: 2022-09-18 13:31:12
 * @Description:
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,  //是否登录成功
    displayArea: {
      top: 'height: 78px;',
      topTow: 'top: 78px;',
      leftWidth: 'width: 300px;',
      rightWidth: 'width: calc(100vw - 300px);',
      height: 'height: calc(100vh - 78px);'
    },
    ws: null,
    userInfo: {}, //用户信息
    groupUserData: [], //群组用户数据
    groupOnline: [], //群组在线
    userSpeech: {
      priority: 0,
      isShow: false,    //自己讲话显示
      isBool: false,   //用户讲话显示
      title: "",      //自己讲话显示
      userTitle: "",    //用户讲话显示
      gid: 0, //群组id
      groupname: "", //群组名称
      uid: 0, //用户id
      name: "" //用户名称
    },
    userService: {}, //用户服务
    groupData: [],    //选中名称的数据
    efId: 0, //区域id
    soketUserInfo: {},    //soket用户信息
    navId: 1, //顶部导航栏id
    audio_enable: 1,    //是否被摇闭
    isLinkSuccess: false, //是否链接成功
    prohibitRoll: false, //禁止滚动
    pttIntercom: false, //判断空格还是点击事件
    isFocusing: false, //是否聚焦input框
    isBroadcast: false, //是否在播报
    isTextBb: false, //是否文字播报
    isSpeech: false, //是否有人讲话
    onlineStatus: -1, //筛选状态
    sosPushInfo: [],
    sosIndex: 0,
    calltThePolice: null, //报警声音
    calltThePoliceTimer: null,
    calltThePoliceType: 0,
    gbInfoText: [], // 文字广播信息
    gbInfoVoice: [], //语音广播信息
    calltThePoliceTimer1: null,
    calltThePoliceTimerTime: 0,
    handleData: [], //被处理的数据
    sosCallthepolice: false,    //sos报警
    toUpdateEnclosure: 0,   //更新围栏
    enclosureTc: [],     //围栏透传
    isMappingTool: false,   //是否显示绘图工具
    efRemark: '1',           //1是自定义  2是区域
    groupType: 0,     //创建区域类型
    recordAddRegionNum: 0,     //记录创建区域群组次数
    videoToken: null,   //视频token头
    videoCallInfo: {
      switchVideo: 0,    //切换
      isMute: false, //是否静音
      callType: 0,   //1呼叫别人  2呼叫自己
      connectState: 0, //0连接中
      callName: '',  //呼叫名称
      recordId: 0,   //消息记录 ID
      userId: 0,     //被呼人的id
      ownId: 0,        //自己的id
      videoPull: null,   //用户
      videoPush: null,    //自己
      echoList: [],      //视频回传列表
      forcedpullEchoList: [],  //强拉视频回传列表
      forcedpullList: [], //回传列表
      isAnswerVideo: false
    },   //视频呼叫信息
    voiceCallInfo: {
      callType: 0,   //1呼叫别人  2呼叫自己
      callName: '',  //呼叫名称
      isAnswerVoice: false
    },   //视频呼叫信息
    userState: {
      isBindRoad: 0, // 是否绑路 0 否 1是
      sosVoice: 0, //sos声音开关
      speechRecognition: 0, //语音识别
      fullDuplex: 0, //全双工
      fullDuplexAudioTime: 0,
      isTalkButton: 0, //对讲按键显示
      enclosureVoice: 0 //围栏声音
    },
    tipsVoice: null,    //提示音
    radiobroadcast: {   //广播功能
      url: '',       //广播路径
      duration: 0,    //广播录制时长
      time: null     //时长
    },
    fullduplex: {    //全双工
      account: 0,  //会话id
      uid: 0,     //
      accept: 0,    //是否接受
      with_video: ''  // 0拒绝  1接听  2正忙  4 未接通
    },
    recordLable: [],   //记录当前关闭百度地图Lable的id调度对讲
    fenceLable: [],   //记录当前关闭百度地图Lable的id电子围栏
    uploadArr:[],      //图片上传透传
    isVideoOne: true  //用户判断查询强拉回传列表只展示一次
  },
  mutations: {},
  actions: {},
  modules: {}
});
