/*
 * @Author: LZH
 * @Date: 2023-11-03 10:26:21
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-01-08 16:59:37
 * @Description: 
 */
// const baseconfig = {
//   xinextendUrlapi: 'https://apidev.xin-ptt.com:9001/xinextend',
//   apiAudioUrl: "https://apidev.xin-ptt.com:9001/xinAudio", //测试录音文件平台
//   gpsUrlapi: "https://apidev.xin-ptt.com:9001/xingps", //测试定位地址
//   mmsUrlapi: "https://apidev.xin-ptt.com:9001/mms", //广播
//   videoUrlapi: "https://apidev.xin-ptt.com:9001/video", //视频
//   sosUrlapi: "https://apidev.xin-ptt.com:9001/sos", //sos
//   regionUrlapi: "https://apidev.xin-ptt.com:9001/region", //区域群组
//   enclosureUrlapi: "https://apidev.xin-ptt.com:9001/enclosure",                               //电子围栏
//   getDownPath: "https://apidev.xin-ptt.com:20008",
//   ak: "gTV5Y1Vvppy3nUFTHQlIBvo5tVx4bqf8", //测试定位地址
//   tokenSl: { "Content-Type": "application/x-www-form-urlencoded" },
//   socketPz: '49.73.61.229:10001',
//   poc: 'xin'
// };

const baseconfig = {        //正式
  xinextendUrlapi: 'https://dmp.hpyjcloud.com/extend',
  apiAudioUrl: 'https://dmp.hpyjcloud.com/recordapi',//录音文件平台
  gpsUrlapi: 'https://dmp.hpyjcloud.com/gps',//定位地址
  mmsUrlapi: 'https://dmp.hpyjcloud.com/mms',//广播
  videoUrlapi: 'https://dmp.hpyjcloud.com/video',//视频
  sosUrlapi: 'https://dmp.hpyjcloud.com/sos',//sos
  regionUrlapi: "https://dmp.hpyjcloud.com/region", //区域群组
  enclosureUrlapi: "https://dmp.hpyjcloud.com/enclosure",                               //电子围栏
  getDownPath: "https://dmp.hpyjcloud.com:20008",
  ak: 'gTV5Y1Vvppy3nUFTHQlIBvo5tVx4bqf8',//定位地址
  tokenSl: { "Content-Type": "application/x-www-form-urlencoded" },
  socketPz: '47.101.42.90:10002',
  poc: 'csptt'
}

export default baseconfig;
